<template>
  <v-card
    height="100%"
  >
    <v-card-title class="py-0">
      <v-toolbar
        flat
        class="px-0"
      >
        <v-toolbar-title class="text-body-h4">
          {{ title }}
        </v-toolbar-title>
        <v-chip
          class="ml-2 font-weight-bold"
          pill
          small
        >
          {{ accept?.replace(/image\//gi, '')?.toUpperCase() }}
        </v-chip>
        <v-spacer />

        <Dialog
          v-if="canDeleteImage && !disabled"
          action="delete"
          :title="$t('settings.company.images.image-container.title', { title })"
          :content="$t('settings.company.images.image-container.content')"
          :submit="() => deleteImage(name)"
        >
          <v-icon
            color="grey"
            style="right: -20px"
          >
            mdi-delete
          </v-icon>
        </Dialog>
      </v-toolbar>
    </v-card-title>

    <v-card-text>
      <!-- Preview Image -->
      <v-img
        v-if="src"
        :src="src"
        height="150px"
        contain
        class="my-2"
      />
      <div
        v-else
        style="height: 150px"
        class="my-2 grey lighten-3 rounded d-flex flex-column justify-center align-center"
      >
        <v-icon
          x-large
          contain
          color="grey"
        >
          mdi-emoticon-sad-outline
        </v-icon>
        <span class="grey--text text-caption font-weight-bold">{{ $t('settings.company.images.image-container.no-image') }}</span>
      </div>

      <!-- Description of requirements -->
      <div class="mb-4 text-body-2">
        {{ description }}
      </div>

      <CropImageUpload
        :min-height="minHeight"
        :min-width="minWidth"
        :min-opacity="minOpacity"
        :accept="accept"
        :ratio="ratio"
        :disabled="disabled"
        :ask-for-image-rights="askForImageRights"
        :save="(image) => saveImage(image,name)"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import CropImageUpload from '@/components/CropImageUpload'
import Dialog from './forms/Dialog.vue'

export default {
  components: { CropImageUpload, Dialog },
  props: {
    name: {
      type: String,
      default: 'logo'
    },
    title: {
      type: String,
      default: 'Logo'
    },
    description: {
      type: String,
      default: ''
    },
    src: {
      type: String,
      default: ''
    },
    minHeight: {
      type: Number,
      default: 1200
    },
    minWidth: {
      type: Number,
      default: 1200
    },
    minOpacity: {
      type: Number,
      default: 0
    },
    ratio: {
      type: Number,
      default: 0
    },
    accept: {
      type: String,
      default: 'image/png,image/jpg'
    },
    canDeleteImage: {
      type: Boolean,
      default: false
    },
    askForImageRights: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    saveImage: {
      type: Function,
      default: () => {}
    },
    deleteImage: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

<style>

</style>
